import { Component, Vue } from "vue-property-decorator";

@Component({
  inheritAttrs: false,
  components: {
    DesktopDateRangePicker: () =>
      import("./desktop/DesktopDateRangePicker/DesktopDateRangePicker.vue"),
    MobileDateRangePicker: () =>
      import("./mobile/MobileDateRangePicker/MobileDateRangePicker.vue"),
  },
})
export default class DateRangePicker extends Vue {}
